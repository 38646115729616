(function () {
  'use strict';

  class AdminCtrl {
    constructor() {
      let vm = this;
      vm.ctrlName = 'AdminCtrl';
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.admin.controller:AdminCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.admin')
    .controller('AdminCtrl', AdminCtrl);
}());
